import { FC, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { FcSurvey } from 'react-icons/fc';
import { FaCcMastercard } from 'react-icons/fa';
import ClientesServices from '../../Services/Catalogos/Clientes.Services';
import { AxiosError } from 'axios';
import IClientes from '../../Interfaces/Catalogos/IClientes';
import { MsgInformativo } from '../Utils/Toast/msgInformativo';
import { BiPackage } from 'react-icons/bi';

export default function DashboardClientes() {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [clientes, setClientes] = useState(() => {
    const stickyValue = window.localStorage.getItem('clientesAsignados')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  //const [ShowEstadosCuenta, setShowEstadosCuenta] = useState(false)
  const [show, setShow] = useState(false)
  const [msg, setMsg] = useState('')
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  //

  /* useEffect(() => {
      ClientesServices.getUsuariosAsignados(UserId)
      .then(resp => {
        setClientes(resp.data)
        let cliente = resp.data.filter((x) => x.sClave===1896 && x.agrupado===1)
        if(cliente.length > 0){
          setShowEstadosCuenta(true)
        }
      })
      .catch((err: AxiosError)=>{
          setShow(true)
          setMsg(err.request.responseText)
          setHeader('Error')
          return
      })
  }, [])    */

  return (
    <>
      <Col>
        <Card style={{ width: '18rem' }} className='dashboardComponentFont'>
          <Card.Header style={{fontSize:'15.9px'}}>
            Clientes
          </Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>
              Traficos
              <br />
              <br />
            </Card.Title>
            <Card.Img variant='top' src='' onClick={() => {}} />
            <div style={{ fontSize: '6em' }} className='text-center'>
              <FcSurvey />
            </div>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs={6} style={{ paddingRight: '5px' }}>
                {/*                 <Link
                  to={`../RptCorresponsalesTraficosHst?proc=0&status=0`}
                  style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
                >
                  Buscar
                </Link> */}
                {/* <span style={{ fontWeight: 'bold' }}>Tipo cambio: ${TipoCambio}</span> */}
              </Col>
              {/*  <Col xs={3}></Col> */}
              <Col xs={6} style={{ paddingRight: '5px' }}>
                <Link
                  to={`../Clientes/Traficos`}
                  style={{
                    textDecoration: 'none',
                    float: 'right',
                    paddingRight: '10px',
                  }}
                >
                  Ver mas...
                </Link>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Col>
      {clientes && clientes.includes(1896) ? <>
      <Col>
        <Card style={{ width: '18rem' }} className='dashboardComponentFont'>
          <Card.Header>Clientes</Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>
              Estados de cuenta
              <br />
              <br />
            </Card.Title>
            <Card.Img variant='top' src='' onClick={() => {}} />
            <div style={{ fontSize: '6em' }} className='text-center'>
              <IconContext.Provider value={{ color: 'orange' }}>
                <FaCcMastercard />
              </IconContext.Provider>
            </div>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to='../Clientes/EstadosCuenta'
              style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
      </> : ''}
      <Col>
        <Card style={{ width: '18rem' }} className='dashboardComponentFont'>
          <Card.Header>Clientes</Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>
              Avisos de Arribos
              <br />
              <br />
            </Card.Title>
            <Card.Img variant='top' src='' onClick={() => {}} />
            <div style={{ fontSize: '6em' }} className='text-center'>
              <IconContext.Provider value={{ color: '#c85a32' }}>
                <BiPackage />
              </IconContext.Provider>
            </div>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to='../Clientes/AvisosArribos'
              style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
      <MsgInformativo
            show={show}
            msg={msg}
            header={header}
            msgColor={msgColor}
            closeToast={function (arg: boolean): void {
            setShow(false)
            }}
        />
    </>
  );
};
