import { useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { DTOAvisoArriboDocumentos } from '../../../DTO/Clientes/AvisosArribos/DTOAvisoArriboDocumentos'
import AvisosFileManagerServices from '../../../Services/Utils/AvisosFileManager.Services'
import { Axios } from 'axios'
import AvisosFileManager from '../../Utils/AvisosFileManager/AvisosFileManager'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'


interface IProps {
  idAviso: number,
  UserId: number
  Documentos: DTOAvisoArriboDocumentos[]
}
export const AvisosArribosDocuments = ({idAviso, UserId, Documentos} : IProps) => {
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  
  const MarkAsRejected = (document: DTOAvisoArriboDocumentos) => {
    if(document.comentarios.trim().length < 1){
      setHeader('Error')
      setMsg('Debe capturar un comentario valido')
      setShowMsg(true)
      return
    }
    AvisosFileManagerServices.MarkAsRejected(document)
    .then((resp) => {
      setHeader('Informativo')
      setMsg('Documento rechazado correctamente')
      setShowMsg(true)
    })
    .catch((error:Axios) => {
      setHeader('Error')
      setMsg('Ocurrió un erro al rechazar el archivo')
      setShowMsg(true)
    })
  }

  return (
    <div style={{height:'550px', overflowY:'scroll'}}>
      <Container className='p-4'>
        {
          Documentos ? Documentos.map(doc => {
            return (
              <>
              <Row>
                <Col xs={5}>
                  PDF {doc.documento}
                  <AvisosFileManager 
                    IDProcess={doc.idRutaArchivo} 
                    IDUser={UserId} 
                    IdFile={idAviso} 
                    FileName={''} 
                    Leyenda={'Se mostrará el documento cuando se cargue'} 
                    canDelete={false} 
                    FileType={['pdf', 'PDF']} 
                    width={'100%'} 
                    height={100} 
                    onAppendFM={function (idFile: number): void {
                      // setIdPdfFactura(idFile)
                    } } 
                  />
                </Col>
                <Col xs={6}>
                  <Form.Control as='textarea' defaultValue={doc.comentarios} disabled={doc.idDocumento === 0 || doc.rechazado} rows={3} onChange={(e) => {doc.comentarios = e.target.value}}/>
                </Col>
                <Col xs={1} style={{lineHeight:'91px', textAlign:'center'}}>
                  <Button variant='danger' disabled={doc.idDocumento === 0 || doc.rechazado} onClick={() => MarkAsRejected(doc)}>Rechazar</Button>
                </Col>
              </Row>
              <hr />
              </>
            )
          }) : ''
        }
      </Container>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
