import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HashRouter, Outlet, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { Login } from './Components/Login/login'
import CatTabuladores from './Components/Catalogos/CatTabulador/CatTabulador'
import DashboardCorresponsales from './Components/Dashboard/DashboardCorresponsales'
import Dashboard from './Components/Dashboard/Dashboard'
import Logout from './Components/Logout/logout'
import Register from './Components/Register/register'
import RegisterPermisoClientes from './Components/registerPermisoClientes/registerPermisoClientes'
import TraficoCorresponsales from './Components/Corresponsales/TraficoCorresponsales'
import RptCorresponsalesTraficos from './Components/Reportes/RptCorresponsalesTraficos'
import RptCorresponsalesTraficosHst from './Components/Reportes/RptCorresponsalesTraficosHst'
import ForgotPassword from './Components/Login/ForgotPassword'
import PIN from './Components/Login/PIN'
import UserControl from './Components/UserControl/UserControl'
import ResetCredentials from './Components/Login/ResetCredentials'
import { ReporteSemanalNewell } from './Components/ReportesClientes/ReporteSemanalNewel'
import { ReporteMensualNewell } from './Components/ReportesClientes/ReporteMensualNewell'
import { CatCentroConstos } from './Components/Catalogos/CatCentroCostos/CatCentroCostos'
import { ReportesEmbarques } from './Components/ReportesClientes/Embarques/ReportesEmbarques'
import { ClientesExternos } from './Components/Clientes/Clientes'
import { ReporteGC50 } from './Components/ReportesClientes/GC50/ReporteGC50'
import RptClientesTraficos from './Components/Clientes/Traficos/RptClientesTraficos'
import { RelacionFacturas } from './Components/ReportesClientes/Alen/RelacionFacturas'
import { ReporteFacturasConsolidados } from './Components/ReportesClientes/Alen/ReporteFacturasConsolidados'
import { CuentasCerradas } from './Components/ReportesClientes/CuentasCerradas.tsx/CuentasCerradas'
import { ReporteArchivoElectronico } from './Components/ReportesClientes/ReporteArchivoElectronico'
import { EstadosCuenta } from './Components/EstadosCuenta/EstadosCuenta'
import { ReporteGeneralFacturacion } from './Components/ReportesClientes/Facturacion/ReporteGeneral'
import { AvisosArribos } from './Components/Clientes/Arribos/AvisosArribos'
require (`./css/${process.env.REACT_APP_ENVIRONMENT}-home.css`)

function PageNotFound() {
  return (
    <div style={{ textAlign: 'center', paddingTop: '300px' }}>
      <h2>Sorry, no matching page</h2>
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/" element={<Dashboard />}></Route>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path='/forgotPassword' element={<ForgotPassword />} />
            <Route path='/PIN' element={<PIN />} />
            <Route path='/UserControl' element={<UserControl />} />
            <Route path="/reset" element={<ResetCredentials />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/permisos" element={<RegisterPermisoClientes />} />
            <Route
              path="/TraficoCorresponsales"
              element={
                <TraficoCorresponsales
                  IDTrafico={0}
                  onClose={function (val: boolean): void {
                    throw new Error('Function not implemented.')
                  }}
                />
              }
            />
            {/* <Route
              path="/DashboardCorresponsales"
              element={<DashboardCorresponsales />}
            /> */}
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route
              path="/RptCorresponsalesTraficos/:proc/:modo"
              element={<RptCorresponsalesTraficos />}
            />
            <Route
              path="/RptCorresponsalesTraficosHst"
              element={<RptCorresponsalesTraficosHst />}
            />
            <Route path="/CatTabuladores" element={<CatTabuladores />} />
            <Route path="*" element={<PageNotFound />} />
            
            <Route path='CentroCostos' element={<CatCentroConstos/>}/>
            <Route path="Reportes" element={<Outlet/>}>
              <Route path='/Reportes/Newell/Semanal' element={<ReporteSemanalNewell/>}/>
              <Route path='/Reportes/Newell/Mensual' element={<ReporteMensualNewell/>}/>
              <Route path='/Reportes/GC50' element={<ReporteGC50/>}/>
              <Route path='/Reportes/CuentasCerradas' element={<CuentasCerradas/>}/>
              <Route path='/Reportes/Alen' element={<Outlet/>}>
                <Route path='/Reportes/Alen/RelacionFacturas' element={<RelacionFacturas/>}/>
                <Route path='/Reportes/Alen/FacturasConsolidados' element={<ReporteFacturasConsolidados/>}/>
              </Route>
              <Route path='/Reportes/ArchivoElectronico/' element={<ReporteArchivoElectronico/>}/>
              <Route path='/Reportes/Facturacion/ReporteGeneral/' element={<ReporteGeneralFacturacion/>}/>
            </Route>
            <Route path="Clientes" element={<Outlet/>}>{/*Aqui van todas las rutas relacionadas a los clientes */}
              <Route path='/Clientes/Reportes/' element={<ClientesExternos/>}>{/*Aqui van todas las rutas relacionadas a los reportes de los clientes*/}
                <Route path='/Clientes/Reportes/Heineken/' element={<Outlet/>}>{/*Aqui van todas las rutas relacionadas a los reportes de Heineken*/}
                  <Route path='/Clientes/Reportes/Heineken/ReportesEmbarques' element={<ReportesEmbarques/>}/>
                </Route>
              </Route>
              <Route path='/Clientes/Traficos/' element={<RptClientesTraficos/>}/>
              <Route path='/Clientes/EstadosCuenta/' element={<EstadosCuenta/>}/>
              <Route path='/Clientes/AvisosArribos' element={<AvisosArribos/>}/>
            </Route>
          </Route>
        </Routes>
      </HashRouter>
  </Provider>
)

reportWebVitals()

