import React, { FC, useEffect, useState } from 'react'
import { Card, Col } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { FaCcMastercard, FaFileInvoiceDollar, FaMoneyBillAlt, FaReply } from 'react-icons/fa'
import { FcSurvey } from 'react-icons/fc'
import { Link } from 'react-router-dom'
import DashboardDataService from '../../Services/Dashboard/Dashboard.Contabilidad.Service'
import DashboardCorresponsales from './DashboardCorresponsales'
interface IProps {
  Depto: string;
  Perfil:string;
}

export const DashboardContabilidad: FC<IProps> = (props) => {
  const [CTCTotal, setCTCTotal] = useState(0) // Corresponsales: Traficos terminados listos para contabilidad
  const [CAPATotal, setCAPATotal] = useState(0) // Corresponsales anticipos pendientes de autorizar
  const [CuentasComplementariasPedientes, setCuentasComplementariasPendientes] = useState(0) // Corresponsales anticipos pendientes de autorizar
  const [RechazosContabilidad, setRechazosContabilidad] = useState(0);
  const [TraficosConCargos, setTraficosConCargos] = useState(0);

  useEffect(() => {
    DashboardDataService.getTotalTraficosPendientesAutorizar(4)
      .then((response) => {
        setCTCTotal(response.data.total)
      })
      .catch((e: Error) => {
        return
      })
    DashboardDataService.getTotalAnticiposPendientes()
      .then((response) => {
        setCAPATotal(response.data.total)
      })
      .catch((e: Error) => {
        return
      })
    DashboardDataService.getTotalCuentasComplementariasPedientes()
      .then((response) => {
        setCuentasComplementariasPendientes(response.data.total)
      })
      .catch((e: Error) => {
        return
      })

    DashboardDataService.getTotalRechazosContabilidad().then((response) => {
      setRechazosContabilidad(response.data.total)
    }).catch((e: Error) => {
      return;
    })
    DashboardDataService.GetTraficosConCargos().then((response) => {
      setTraficosConCargos(response.data.total)
    }).catch((e: Error) => {
      return;
    })
  }, [])

  return (
    <>
      {
       props.Depto === 'Contabilidad' ?  <DashboardCorresponsales Perfil={props.Perfil}/> : ""
      }
      <Col>
        <Card style={{ width: '18rem' }} className='dashboardComponentFont'>
          <Card.Header>Contabilidad</Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>
              Traficos pendientes
              <br />
              <br />
            </Card.Title>
            <Card.Img variant='top' src='' onClick={() => {}} />
            <div style={{ fontSize: '6em' }} className='text-center'>
              <FcSurvey />
              {CTCTotal}
            </div>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to='../RptCorresponsalesTraficos/proc=2/modo=1'
              style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
      <Col>
        <Card style={{ width: '18rem' }} className='dashboardComponentFont'>
          <Card.Header>Contabilidad </Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>&nbsp;Anticipos pendientes de autorizar</Card.Title>
            <Card.Img variant='top' src='' onClick={() => {}} />
            <div style={{ fontSize: '6em' }} className='text-center'>
              <IconContext.Provider value={{ color: 'orange' }}>
                <FaCcMastercard />
              </IconContext.Provider>
              &nbsp;{CAPATotal}
            </div>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to='../RptCorresponsalesTraficos/proc=2/modo=2'
              style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
      <Col>
        <Card style={{ width: '18rem' }} className='dashboardComponentFont'>
          <Card.Header>Contabilidad </Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>&nbsp;Cuentas complementarias pendientes</Card.Title>
            <Card.Img variant='top' src='' onClick={() => {}} />
            <div style={{ fontSize: '6em' }} className='text-center'>
              <IconContext.Provider value={{ color: '#AAF866' }}>
                <FaFileInvoiceDollar />
              </IconContext.Provider>
              {CuentasComplementariasPedientes}
            </div>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to='../RptCorresponsalesTraficos/proc=2/modo=3'
              style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
      <Col>
        <Card style={{ width: '18rem' }} className='dashboardComponentFont'>
          <Card.Header>Contabilidad </Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>&nbsp;Rechazos para corresponsalias</Card.Title>
            <Card.Img variant='top' src='' onClick={() => {}} />
            <div style={{ fontSize: '6em' }} className='text-center'>
              <IconContext.Provider value={{ color: '#ff0018' }}>
                <FcSurvey style={{fontSize:'larger'}}/>
                <FaReply style={{fontSize:'50px', margin:'0 0 0 -4rem', position:"absolute", bottom: '6.5rem'}}/>
              </IconContext.Provider>
              {RechazosContabilidad}
            </div>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to='../RptCorresponsalesTraficos/proc=2/modo=4'
              style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
      <Col>
        <Card style={{ width: '18rem', maxHeight:'100%', height:'100%' }} className='dashboardComponentFont'>
          <Card.Header>Contabilidad </Card.Header>
          <Card.Body style={{ paddingBottom: '0px' }}>
            <Card.Title>&nbsp;Traficos Con Cargos de Dlls<br/></Card.Title>
            <div style={{ fontSize: '6em' }} className='text-center'>
              <IconContext.Provider value={{ color: '#428d00' }}>
                <FcSurvey style={{fontSize:'larger'}}/>
                <FaMoneyBillAlt   style={{fontSize:'80px', margin:'0 0 0 -3rem', position:"absolute", bottom: '9rem'}}/>
              </IconContext.Provider>
            </div>
            <div style={{fontSize:'60px', textAlign:'center'}}>{TraficosConCargos}</div>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to='../RptCorresponsalesTraficos/proc=2/modo=5'
              style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
    </>
  )
}
