import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import {
  BsCash,
  BsCheckCircle,
  BsCheckCircleFill,
  BsEraserFill,
  BsFileEarmarkPdf,
  BsFillXCircleFill,
  BsPencilFill,
  BsQuestionOctagonFill,
  BsTrash,
} from 'react-icons/bs'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import 'react-flexy-table/dist/index.css'
import DTOItems from '../../../DTO/Corresponsales/DTOItems'
import CAnticiposDataService from '../../../Services/Corresponsalias/Corresponsales.Anticipos.Services'

import {
  populateCorresponsalesAnticipos,
  addCorresponsalesAnticipos,
  deleteCorresponsalesAnticipos,
  updateCorresponsalesAnticipos,
} from '../../../store/features/Corresponsales/CorresponsalesAnticiposSlice'
import ICorresponsalAnticipos from '../../../Interfaces/Corresponsales/ICorresponsalAnticipos'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { AgGridReact } from 'ag-grid-react'
import { RowClickedEvent } from 'ag-grid-community'
import { BiCoinStack, BiDownload } from 'react-icons/bi'
import CurrencyFormat from 'react-currency-format'
import FileManager from '../../Utils/FileManager/FileManager'
import DTOAnticiposAutoriza from '../../../DTO/Corresponsales/DTOAnticiposAutoriza'
import CorresponsalesAnticiposServices from '../../../Services/Corresponsalias/Corresponsales.Anticipos.Services'

interface ItemList {
  id: number
  item: string
}

interface IProps {
  IDTrafico: number
  FolioTrafico: string
  closeCRUDItems: (arg: boolean) => void
  canDelete: boolean
  canUseActions: boolean
}

export const Anticipos: FC<IProps> = (props) => {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Depto, setDept] = useState(() => {
    const Depto = window.localStorage.getItem('Departamento')
    return Depto !== null ? JSON.parse(Depto) : ''
  })
  const mCAnticipos = useSelector((state: RootState) => state.CAnticipos.CorresponsalesAnticipos)
  const [allItems, setAllItems] = useState<DTOItems[]>([])
  const [filteredData, setFilteredData] = useState<ICorresponsalAnticipos[]>([])
  const [IdAnticipo, setIdAnticipo] = useState(0)
  const [Anticipo, setAnticipo] = useState(0)
  const [Moneda, setMoneda] = useState(2)
  const [Financiado, setFinanciado] = useState(0)
  const [Concepto, setConcepto] = useState('')
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [msgDialog, setMsgDialog] = useState(false)
  const [msgDeleteDialog, setMsgDeleteDialog] = useState(false)
  const [MsgDialogFileManager, setMsgDialogFileManager] = useState(false)
  const [msgDialogFileManagerConta, setMsgDialogFileManagerConta] = useState(false)
  const gridRef = React.useRef<any>(null)
  const btnSaveRef = React.useRef<HTMLButtonElement>(null)
  const [deleteFile, setDeleteFile] = useState(false)
  const [columnDefs] = useState([
    props.canUseActions
      ? {
          field: 'id',
          headerName: 'Acciones',
          width: 110,
          sortable: true,
          filter: true,
          cellRenderer: (params: any) => {
            return (
              Depto === 'Corresponsalias' ?
              <div>
                <IconContext.Provider value={{ color: 'blue', size: '18px' }}>
                  <BsPencilFill
                    onClick={() => {
                      loadRow(params.data)
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </IconContext.Provider>
                &nbsp;&nbsp;&nbsp;
                <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                  <BsFileEarmarkPdf
                    onClick={() => {
                      viewDoc(params.data)
                      //getFileContent(params.value)
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </IconContext.Provider>&nbsp;&nbsp;&nbsp;
                <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                  <BsTrash
                    onClick={() => {
                      setIdAnticipo(params.data.id)
                      setConcepto(params.data.concepto)
                      setAnticipo(params.data.anticipo)
                      setMsgDeleteDialog(true)
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </IconContext.Provider>
              </div> : 
              <div>
                <IconContext.Provider value={{ color: 'blue', size: '18px' }}>
                  <BiDownload
                    onClick={() => {
                      downloadAnticipo(params.data.id, params.data.concepto)
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </IconContext.Provider>
              </div>
            )
          },
        }
      : {
          field: 'id',
          headerName: 'id',
          width: 60,
          sortable: false,
          filter: false,
          visible: false,
        },
    {
      field: 'anticipo',
      headerName: 'Anticipo',
      width: 120,
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return (
          <CurrencyFormat
            value={params.value}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        )
      },
    },
    {
      field: 'moneda',
      headerName: 'Moneda',
      width: 105,
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        if (params.value === 1) {
          return (
            <IconContext.Provider value={{ color: '#E5E041', size: '18px' }}>
              <BiCoinStack /> Pesos
            </IconContext.Provider>
          )
        } else if (params.value === 2) {
          return (
            <IconContext.Provider value={{ color: 'green', size: '18px' }}>
              <BsCash /> Dollar
            </IconContext.Provider>
          )
        } else return '?'
      },
    },
    {
      field: 'financiado',
      headerName: '¿Financiado?',
      width: 135,
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        if (params.value === 1)
          return (
            <div style={{ paddingLeft: '30px' }}>
              <IconContext.Provider value={{ color: 'green', size: '20px' }}>
                <BsCheckCircleFill />
              </IconContext.Provider>
            </div>
          )
        else
          return (
            <div style={{ paddingLeft: '30px' }}>
              <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                <BsFillXCircleFill />
              </IconContext.Provider>
            </div>
          )
      },
    },
    {
      field: 'id',
      headerName: 'Autorizado',
      width: 130,
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        if (params.data.autoriza === 0)
          return (
            <div style={{ paddingLeft: '30px' }}>
              <IconContext.Provider value={{ color: 'red', size: '18px' }}>
                <BsQuestionOctagonFill
                  onClick={() => {
                    return Depto === 'Contabilidad' ? viewDocConta(params.data) : '#'
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </IconContext.Provider>
            </div>
          )
        else
          return (
            <div style={{ paddingLeft: '30px' }}>
              <IconContext.Provider value={{ color: 'green', size: '18px' }}>
                <BsCheckCircleFill
                  onClick={() => {
                    viewDocConta(params.data)
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </IconContext.Provider>
            </div>
          )
      },
    },
    { field: 'concepto', headerName: 'Concepto', width: 350, sortable: true, filter: true },
    { field: 'sSolicita', headerName: 'Solicita', width: 150, sortable: true, filter: true },
    {
      field: 'fhSolicita',
      headerName: 'Fecha solicitud',
      width: 200,
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        if (params.value !== null) {
          return params.value.substring(0, 16)
        }
      },
    },
    { field: 'sAutoriza', headerName: 'Authoriza', width: 150, sortable: true, filter: true },
    { field: 'fhAutoriza', headerName: 'Fecha autorizacion', width: 200, sortable: true, filter: true },
  ])
  const dispatch = useDispatch()

  const cleanForm = () => {
    setIdAnticipo(0)
    setAnticipo(0)
    setMoneda(2)
    setConcepto('')
  }

  const saveForm = () => {
    if (Concepto.length < 5) {
      btnSaveRef.current!.disabled = false;
      setHeader('Error')
      setMsg('Para poder guardar la informacion, proporcione el concepto')
      setShowMsg(true)
      return
    }
    if (Anticipo === 0) {
      btnSaveRef.current!.disabled = false;
      setHeader('Error')
      setMsg('Para poder guardar la informacion, proporcione el monto del anticipo')
      setShowMsg(true)
      return
    }
    const data: ICorresponsalAnticipos = {
      id: IdAnticipo,
      idTrafico: props.IDTrafico,
      anticipo: Anticipo,
      moneda: Moneda,
      concepto: Concepto,
      solicita: UserId,
      fhSolicita: '',
      financiado: Financiado,
    }
    CAnticiposDataService.Append(data)
      .then((response) => {
        if (response.status === 200) {
          btnSaveRef.current!.disabled = false;
          setHeader('Informativo')
          setMsg('La informcion se guardo correctamente')
          setShowMsg(true)
          if (IdAnticipo === 0) dispatch(addCorresponsalesAnticipos(response.data))
          else dispatch(updateCorresponsalesAnticipos(response.data))
        }
      })
      .catch((e: Error) => {
        btnSaveRef.current!.disabled = false;
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadRow = (data: ICorresponsalAnticipos) => {
    setIdAnticipo(data.id)
    setAnticipo(data.anticipo)
    setConcepto(data.concepto)
    setMoneda(data.moneda)
  }

  const viewDoc = (data: ICorresponsalAnticipos) => {
    loadRow(data)
    setMsgDialogFileManager(true)
  }

  const viewDocConta = (data: ICorresponsalAnticipos) => {
    loadRow(data)
    setMsgDialogFileManagerConta(true)
  }

  const authorizeItem = (data: ICorresponsalAnticipos) => {
    loadRow(data)
    setMsgDialog(true)
  }

  const authorizeAmount = async (idAnticipo: number, idUsuario: number) => {
    const data: DTOAnticiposAutoriza = {
      id: idAnticipo,
      idUsuario: idUsuario,
    }
    await CAnticiposDataService.Autoriza(data)
      .then((response) => {
        if (response.status == 200) {
          dispatch(updateCorresponsalesAnticipos(response.data))
          setMsgDialog(false)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const deleteItem = () => {
    CAnticiposDataService.Delete(IdAnticipo)
      .then((response) => {
        if (response.status == 200) {
          dispatch(deleteCorresponsalesAnticipos(IdAnticipo))
          setMsgDeleteDialog(false)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    CAnticiposDataService.getAll(props.IDTrafico)
      .then((response) => {
        dispatch(populateCorresponsalesAnticipos(response.data))
        setFilteredData(mCAnticipos)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }, [props.IDTrafico])

  useEffect(() => {
    const result = mCAnticipos.filter((row) => row.id > 0 && row.idTrafico === props.IDTrafico)
  }, [mCAnticipos])

  const downloadAnticipo = (idAnticipo: number, concepto: string) => {
    CorresponsalesAnticiposServices.DownloadAnticipo(props.IDTrafico, idAnticipo)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Anticipo_${concepto}_${props.FolioTrafico}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      setHeader('Error')
      setMsg('Ocurrio un error al generar el archivo. Por favor, intentelo de nuevo.')
      setShowMsg(true)
      return
    })
  }

  return (
    <div>
      <Container className='labelSize13px'>
        <Row className={props.canDelete ? 'visible' : 'invisible heightZero'}>
          <Col xs='12'>
            <input
              type='text'
              className='form-control genericSelect'
              name='Concepto'
              id='Concepto'
              style={{ height: '30px' }}
              value={Concepto}
              placeholder='Proporcione el concepto'
              onChange={(e) => {
                setConcepto(e.target.value)
              }}
            />
          </Col>
        </Row>
        <Row
          style={{ paddingBottom: '5', paddingTop: '15', alignItems: 'center' }}
          className={props.canDelete ? 'visible' : 'invisible heightZero'}
        >
          <Col md={2} lg={3}>
            <Button
              variant='warning'
              size='sm'
              onClick={() => {
                cleanForm()
              }}
            >
              <BsEraserFill />
              &nbsp;Limpiar
            </Button>
          </Col>
          <Col md={3} lg={2}>
            <CurrencyFormat
              value={Anticipo}
              displayType={'input'}
              thousandSeparator={true}
              prefix={'$'}
              onValueChange={(values: any) => {
                const { formattedValue, value } = values
                setAnticipo(value)
              }}
              style={{
                fontSize: '18px',
                backgroundColor: '#F5FFED',
                border: '2px solid #25D05B',
                width: '150px',
                textAlign: 'right',
                borderRadius: '10px',
              }}
            />
          </Col>
          <Col md={2} lg={2}>
            <Form.Control
              as='select'
              className='form-select form-select-sm'
              value={Moneda}
              onChange={(e) => {
                setMoneda(parseInt(e.target.value))
              }}
            >
              <option value='1'>Pesos</option>
              <option value='2'>Dolares</option>
            </Form.Control>
          </Col>
          <Col md={3} lg={2}>
            <Form.Control
              as='select'
              className='form-select form-select-sm'
              value={Financiado}
              onChange={(e) => {
                setFinanciado(parseInt(e.target.value))
              }}
            >
              <option value='0'>No financiado</option>
              <option value='1'>Financiado</option>
            </Form.Control>
          </Col>
          <Col md={2} lg={3} style={{display:'flex', justifyContent:'end'}}>
            <Button
              variant='primary'
              size='sm'
              onClick={() => {
                btnSaveRef.current!.disabled = true;
                saveForm()
              }}
              ref={btnSaveRef}
            >
              <BsCheckCircle />
              &nbsp;Guardar
            </Button>
          </Col>
          <Form.Control type='hidden' id='IdAnticipo' size='sm' value={IdAnticipo} placeholder='id' disabled={true} />
        </Row>
        <Row style={{ paddingBottom: 5 }}>
          <Col xs={12}>
            <div className='ag-theme-alpine' style={{ height: 300, width: '100%' }}>
              <AgGridReact
                rowData={mCAnticipos.filter((p) => p.id !== 0)}
                columnDefs={columnDefs}
                pagination={true}
                paginationAutoPageSize={true}
                ref={gridRef}
                rowSelection={'single'}
                rowMultiSelectWithClick={true}
                /*  onRowClicked={(e) => getParams(e)} */
              ></AgGridReact>
            </div>
          </Col>
        </Row>
      </Container>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      ></MsgInformativo>
      <Modal show={msgDeleteDialog} onHide={() => setMsgDeleteDialog(false)} size='lg'>
        <Modal.Body>
          <Alert variant='primary'>
            ¿Esta seguro de eliminar ?: '
            <br /> <p>{Concepto}</p>
            Por el monto de:
            <span style={{ backgroundColor: 'red', color: 'yellow', fontWeight: 'bold', fontSize: '20px' }}>
              <CurrencyFormat
                value={Anticipo}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />{' '}
              {Moneda === 1 ? 'Pesos' : 'Dolares'}
            </span>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={1}>
              <Button variant='secondary' onClick={() => setMsgDeleteDialog(false)} size='sm'>
                Cerrar
              </Button>
            </Col>
            <Col xs={5} style={{ paddingLeft: '550px', paddingRight: '0px' }}>
              &nbsp;
            </Col>
            <Col xs={1}>
              <Button variant='primary' onClick={deleteItem} size='sm'>
                Si
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <Modal show={msgDialog} onHide={() => setMsgDialog(false)} size='lg'>
        <Modal.Body>
          <Alert variant='primary'>
            ¿Esta seguro de autorizar?
            <br /> <p>{Concepto}</p>
            Por el monto de:
            <span style={{ backgroundColor: 'red', color: 'yellow', fontWeight: 'bold', fontSize: '20px' }}>
              <CurrencyFormat
                value={Anticipo}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />{' '}
              {Moneda === 1 ? 'Pesos' : 'Dolares'}
            </span>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={1}>
              <Button variant='secondary' onClick={() => setMsgDialog(false)} size='sm'>
                Cerrar
              </Button>
            </Col>
            <Col xs={5} style={{ paddingLeft: '550px', paddingRight: '0px' }}>
              &nbsp;
            </Col>
            <Col xs={1}>
              <Button variant='danger' onClick={() => authorizeAmount(IdAnticipo, UserId)} size='sm'>
                Autorizar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <Modal show={MsgDialogFileManager} onHide={() => setMsgDialogFileManager(false)} size='lg'>
        <Modal.Body>
          <FileManager
            width={750}
            height={200}
            IDProcess={1}
            IdFile={IdAnticipo}
            IDUser={UserId}
            FileName={''}
            canDelete={Depto === 'Contabilidad'}
            FileType={['pdf']}
            Leyenda={'Seleccione el archivo, arrastrelo hasta aqui y sueltelo para subirlo al servidor..'}
            onAppendFM={function (idFile: number): void {}}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={11}>&nbsp;</Col>
            <Col xs={1}>
              <Button variant='secondary' onClick={() => setMsgDialogFileManager(false)} size='sm'>
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <Modal show={msgDialogFileManagerConta} onHide={() => setMsgDialogFileManagerConta(false)} size='lg'>
        <Modal.Body>
          <FileManager
            width={750}
            height={200}
            IDProcess={4}
            IdFile={IdAnticipo}
            IDUser={UserId}
            FileName={''}
            canDelete={Depto === 'Contabilidad'}
            FileType={['pdf']}
            Leyenda={'Seleccione el archivo, arrastrelo hasta aqui y sueltelo para subirlo al servidor...'}
            onAppendFM={function (idFile: number): void {
              if(idFile === IdAnticipo){
                authorizeAmount(IdAnticipo, 0)
              }else{
                authorizeAmount(IdAnticipo, UserId)
              }
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={11}>&nbsp;</Col>
            <Col xs={1}>
              <Button variant='secondary' onClick={() => setMsgDialogFileManagerConta(false)} size='sm'>
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
