import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts"
import { Card, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import DashboardDataService from '../../Services/Dashboard/Dashboard.Corresponsales.Services';

export const DashboardSistemas:React.FC = () => {
    const [TotalCorresponsales, setTotalCorresponsales] = useState(0);
    const [series, setseries] = useState<number[]>([]);
    const [Label, setLabel] = useState('');
    const [CircleOptions, setCirleOpcions] = useState<ApexOptions>({
        labels: [],
          colors: ['#EB984E',],
          legend: { position: 'bottom' },
          plotOptions: {
            pie: {
              donut: {
                size: '55%',
              },
            },
          },
    });

    useEffect(() => {
        DashboardDataService.getDisk()
          .then((response) => {
             console.log(response.data);
            setLabel(response.data.descripcion)
            setseries([response.data.total]);
    
            setCirleOpcions({
              labels: [response.data.descripcion],
              colors: ['#ABEB4E'],
              legend: { position: 'bottom' },
                plotOptions: {
                  radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    track: {
                      background: "#e7e7e7",
                      strokeWidth: '100%',
                      margin: 5, // margin is in pixels
                      dropShadow: {
                        enabled: false,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2
                      }
                    },
                    dataLabels: {
                      name:{
                        show:false
                      },
                      value: {
                        show: true,
                        fontSize: '43px',
                        fontWeight: 'bold',
                      },
                    }
                  }
                },
                grid: {
                  padding: {
                    top: -10
                  }
                },
            });
          })
          .catch((e: Error) => {
            return;
          });
        //getTipoCambio(currentDate())
      }, []);

    return (
        <Col>
        <Card style={{ width: '18rem' }} className='dashboardComponentFont'>
          <Card.Header style={{fontSize:'15.9px'}}>
            <b>Sistemas </b>
          </Card.Header>
          <Card.Body style={{ paddingBottom: '0px'}}>
            <Card.Title>Almacenamiento interno</Card.Title>
              <ReactApexChart
                type='radialBar'
                options={CircleOptions}
                series={series}
                width={250}
                height={310}
              />
              <div style={{textAlign:"center"}}>{Label}</div>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs={6} style={{ paddingRight: '5px' }}>
                {/*                 <Link
                  to={`../RptCorresponsalesTraficosHst?proc=0&status=0`}
                  style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
                >
                  Buscar
                </Link> */}
                {/* <span style={{ fontWeight: 'bold' }}>Tipo cambio: ${TipoCambio}</span> */}
              </Col>
              {/*  <Col xs={3}></Col> */}
              <Col xs={6} style={{ paddingRight: '5px' }}>
                <Link
                  to={``}
                  style={{
                    visibility: 'hidden',
                    textDecoration: 'none',
                    float: 'right',
                    paddingRight: '10px',
                  }}
                >
                  Ver mas...
                </Link>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Col>
    )
}