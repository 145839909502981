import { useEffect, useRef, useState } from "react"
import { Button, Card, Col, Row, Form } from "react-bootstrap"
import ClientesServices from "../../Services/Catalogos/Clientes.Services"
import IClientes from "../../Interfaces/Catalogos/IClientes"
import { AxiosError } from "axios"
import { MsgInformativo } from "../Utils/Toast/msgInformativo"
import { AgGridReact } from "ag-grid-react"
import EstadosCuentaAlenService from "../../Services/EstadosCuenta/EstadosCuentaAlen.Service"
import { DTOEstadoCuentaAlen } from "../../DTO/EstadosCuenta/DTOEstadoCuentaAlen"
import { ColDef, ILargeTextEditorParams } from "ag-grid-community"
import { BsFileEarmarkExcelFill } from "react-icons/bs"
import { DTOEstadoCuentaAlenFilters } from "../../DTO/EstadosCuenta/DTOEstadosCuentaAlenFilters"
import { FaDownload } from "react-icons/fa"
import { IconContext } from "react-icons"
import FileManagerServices from "../../Services/Utils/FileManager.Services"

export const EstadosCuenta:React.FC = () => {
    const [Perfil, setPerfil] = useState(() => {
        const stickyValue = window.localStorage.getItem('Perfil')
        return stickyValue !== null ? JSON.parse(stickyValue) : ''
    })
    const [Cuentas, setCuentas] = useState<DTOEstadoCuentaAlen[]> ([])
    const [show, setShow] = useState(false)
    const [msg, setMsg] = useState('')
    const [header, setHeader] = useState('')
    const [msgColor, setMsgColor] = useState('primary')
    const hiddenFileInputRef = useRef<HTMLInputElement>(null);
    const [showSpinner, setShowSpinner] = useState(false)
    const [File, setFile] = useState<File | null>();
    //Estados de los filtros
    const [Remitentes, setRemitentes] = useState<string[]>([])
    const [Trafico, setTrafico] = useState('')
    const [Aduana, setAduana] = useState('')
    const [Patente, setPatente] = useState('')
    const [Pedimento, setPedimento] = useState('')
    const [TipoOperacion, setTipoOperacion] = useState('')
    const [Inicio, setInicio] = useState('')
    const [Fin, setFin] = useState('')
    const [Remitente, setRemitente] = useState('')
    const [Programado, setProgramado] = useState(0)
    //
    const [columnDefs] = useState<ColDef[]>([
        {
            field:'serie', headerName:'Serie',
            valueGetter: ({data}:any) => {return data.serie + data.noMovimiento},
            width:100,
            filter: true
        },/* {
            field:'noMovimiento', headerName:'Movimiento'
        }, */{
            field:'fecha', filter: true
        },{
            field:'trafico', width:150, sortable: true, filter: true
        },{
            field:'aduana', width:100, filter: true
        },{
            field:'patente', width:100, filter: true
        },{
            field:'pedimento', width:120, filter: true
        },{
            field:'clave', headerName:'Cve. Pedim', width:120, filter: true
        },{
            field:'impexp', headerName: 'Impo/Expo', width:120, filter: true
        },{
            field:'noCliente', headerName: 'No. Cte.', width:100, filter: true
        },{
            field:'nombre', headerName: 'Nombre Cliente', width:300, filter: true
        },{
            field:'cargos', width:100, filter: true
        },{
            field:'abonos', width:100, filter: true
        },{
            field:'saldoFinal', width:120, filter: true
        },{
            field:'ivaAl', headerName: '% IVA', width:100, filter: true
        },{
            field:'descripcion', width:300, wrapText:true, autoHeight: true, filter: true
        },{
            field:'remitente', width:300, wrapText:true, autoHeight: true, filter: true
        },{
            field:'pedido', width:300, wrapText:true, autoHeight: true, filter: true
        },{
            field:'ordenCompra', editable: true, filter: true, wrapText: true, autoHeight:true
        }
        ,{
            field:'tipoMercancia', headerName: 'Tipo Mcia', filter: true
        },
        {
            field:'idPdf', headerName: 'PDF',
            cellRenderer: ({data}:any)=>{
                return (
                    <IconContext.Provider value={{color: 'blue', size:'20px'}}>
                        <FaDownload style={{cursor:'pointer'}} onClick={() => {
                                if(data.trafico[data.trafico.length-1].toUpperCase() === 'C')
                                    getCorresponsaliasFile(data.idPdf, 37, data.pdf)
                                else
                                    getAEO(data.serie, data.noMovimiento, 'pdf', data.trafico);
                            }
                        }/>
                    </IconContext.Provider>
                )
            }
        },
        {
            field:'idXml', headerName: 'XML',
            cellRenderer: ({data}:any)=>{
                return (
                    <IconContext.Provider value={{color: 'blue', size:'20px'}}>
                        <FaDownload style={{cursor:'pointer'}} onClick={() => {
                                if(data.trafico[data.trafico.length-1].toUpperCase() === 'C')
                                    getCorresponsaliasFile(data.idXml, 38, data.xml)
                                else
                                    getAEO(data.serie, data.noMovimiento, 'xml', data.trafico);
                            }
                        }/>
                    </IconContext.Provider>
                )
            }
        },
        {
            field: "observaciones", 
            editable:true,
            filter: true,
            wrapText: true,
            cellEditor: "agLargeTextCellEditor",
            cellEditorPopup: true,
            cellEditorParams: {
                rows: 5,
                maxLength: 1000
              } as ILargeTextEditorParams,
            autoHeight:true 
        },
        /* {
            field:'idNotasCargo', headerName: 'Notas de Cargo',
            cellRenderer: ({data}:any)=>{
                return (
                    data.idNotasCargo !== 0 ? 
                    <IconContext.Provider value={{color: 'blue', size:'20px'}}>
                        <FaDownload style={{cursor:'pointer'}} onClick={() => {
                                if(data.trafico[data.trafico.length-1].toUpperCase() === 'C')
                                    getCorresponsaliasFile(data.idNotasCargo, 41, data.notasCargo)
                                /* else
                                    getAEO(data.serie, data.noMovimiento); 
                            }
                        }/>
                    </IconContext.Provider> : ''
                )
            }
        }, */
    ])
    useEffect(() => {
        getCuentas();
    },[])

    function currentDate(days: number): string {
        var today = new Date()
        today.setDate(today.getDate() + days)
        var dd = String(today.getDate()).padStart(2, '0')
        var mm = String(today.getMonth() + 1).padStart(2, '0')
        var yyyy = today.getFullYear()
        return yyyy + '-' + mm + '-' + dd
      }

    const uploadFile = (file: File) => {
        setShowSpinner(true)
        const formData = new FormData()
        formData.append('name', file.name)
        formData.append('file', file)
        if(Perfil === 'Administrador'){
            EstadosCuentaAlenService.Append(formData)
            .then((res) => {
                hiddenFileInputRef.current!.value = ''
                setCuentas(res.data)
                setShowSpinner(false)
            })
            .catch((err: AxiosError) => {
                setShowSpinner(false)
                hiddenFileInputRef.current!.value = ''
                setShow(true)
                setMsg(err.request.responseText)
                setHeader('Error')
                return
            })
        }
        if(['Facturacion', 'Sup. Facturacion'].includes(Perfil)){
            EstadosCuentaAlenService.AppendProgramacionesPago(formData)
            .then((res) => {
                hiddenFileInputRef.current!.value = ''
                setCuentas(res.data)
                setShowSpinner(false)
            })
            .catch((err: AxiosError) => {
                setShowSpinner(false)
                hiddenFileInputRef.current!.value = ''
                setShow(true)
                setMsg(err.request.responseText)
                setHeader('Error')
                return
            })
        }
    }

    const getCuentas = () => {
        var filters: DTOEstadoCuentaAlenFilters = {
            Trafico: Trafico,
            Impexp: TipoOperacion,
            Aduana: Aduana,
            Patente: Patente,
            Pedimento: Pedimento,
            Inicio: Inicio,
            Fin: Fin,
            Remitente: Remitente,
            Programado: Programado
        }
        EstadosCuentaAlenService.GetAll(filters)
        .then(resp => {
            setCuentas(resp.data)
            if(resp.data.length === 0){
                setShow(true)
                setMsg("No se encontraron registros")
                setHeader('Informativo')
                return;
            }
            if(Remitentes.length === 0){
                let remitentes = resp.data.map((x:any) => {
                    if(x.remitente==='' || x.remitente===null) x.remitente = 'SIN REMITENTE'
                    return x.remitente
                })
                setRemitentes(remitentes.filter(function(elem, index, self) {
                    return index === self.indexOf(elem);
                }).sort((a,b) => (a < b ? -1 : 1)))
            }
        })
        .catch((err: AxiosError) => {
            setShowSpinner(false)
            setShow(true)
            setMsg(err.request.responseText)
            setHeader('Error')
            return
        })
    }

    const selectFile = () => {
        hiddenFileInputRef.current?.click()
    }
    
    const downloadEstadosCuenta = () => {
        EstadosCuentaAlenService.ExportEstadosCuenta(Cuentas)
        .then((resp:any) => {
            const url = window.URL.createObjectURL(new Blob([resp.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', "EstadosDeCuenta.xlsx")
            document.body.appendChild(link)
            link.click()
        }).catch((err:AxiosError) => {
            setShow(true)
            setMsg(err.request.responseText)
            setHeader('Error')
            return;
        })
    }

    const getRowStyle = (params: any) => {
        var styles = {
            backgroundColor:'',
            border: '0.5px solid #aaaaaa',
        }
        /* if (params.data.programado === 1) {
            styles.backgroundColor = 'rgb(98, 190, 255, 0.5)';
        } */
        if(params.data.programado === true){
            styles.backgroundColor = "rgb(255, 193, 7, 0.5)";
        }

        return styles;
    };

    const getCorresponsaliasFile = (id:number, proceso: number, fileName: string) => {
        FileManagerServices.getFileContent(id, proceso)
        .then((resp: any) => {
            const url = window.URL.createObjectURL(new Blob([resp.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
        }).catch((err:AxiosError) => {
            setShow(true)
            setMsg("No se encontró el archivo solicitado")
            setHeader('Error')
            return;
        })
    }
    
    const getAEO = (serie: string, noMovimiento: string, mime: string, trafico: string) => {
        FileManagerServices.getAEO(serie, noMovimiento, mime, trafico)
        .then((resp: any) => {
            const url = window.URL.createObjectURL(new Blob([resp.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `EstadoCuenta_${serie}${noMovimiento}.${mime}`)
            document.body.appendChild(link)
            link.click()
        }).catch((err:AxiosError) => {
            setShow(true)
            setMsg("No se encontró el archivo solicitado")
            setHeader('Error')
            return;
        })
    }

    const onCellValueChanged = (event: any) => {
        var data: DTOEstadoCuentaAlen = event.data;
        let ColDef = event.colDef;
        if(ColDef.field === 'ordenCompra'){
            EstadosCuentaAlenService.AppendOrdenCompra(data)
            .then(() => getCuentas())
            .catch((err:AxiosError) => {
                setShow(true)
                setMsg(err.request.responseText)
                setHeader('Error')
                return;
            })
        }
        if(ColDef.field === 'observaciones'){
            EstadosCuentaAlenService.AppendObservaciones(data)
            .then(() => getCuentas())
            .catch((err:AxiosError) => {
                setShow(true)
                setMsg(err.request.responseText)
                setHeader('Error')
                return;
            })
        }
    }
    return (
        <div>
            <Card>
                <Card.Body>
                    <Row>
                        <Col xs={2}>
                            <Form.Label style={{width:'100%'}}>
                                Trafico
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={Trafico}
                                    onChange={(e) => setTrafico(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{width:'100%'}}>
                                Aduana
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={Aduana}
                                    onChange={(e) => setAduana(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{width:'100%'}}>
                                Patente
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={Patente}
                                    onChange={(e) => setPatente(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Label>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{width:'100%'}}>
                                Pedimento
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={Pedimento}
                                    onChange={(e) => setPedimento(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Label>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{width:'100%'}}>
                                Inicio
                                <Form.Control
                                    defaultValue={Inicio}
                                    type='date'
                                    name='Fecha'
                                    placeholder='Fecha'
                                    title='Fecha'
                                    alt='Fecha'
                                    onChange={(e) => setInicio(e.target.value)}
                                    size='sm'
                                />
                            </Form.Label>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{width:'100%'}}>
                                Fin
                                <Form.Control
                                    defaultValue={Fin}
                                    type='date'
                                    name='Fecha'
                                    placeholder='Fecha'
                                    title='Fecha'
                                    alt='Fecha'
                                    onChange={(e) => setFin(e.target.value)}
                                    size='sm'
                                />
                            </Form.Label>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{width:'100%'}}>
                                Remitente
                                <Form.Control
                                    as='select'
                                    value={Remitente!}
                                    onChange={(e) => {
                                        setRemitente(e.target.value)
                                    }}
                                    className='form-select form-select-sm'
                                >
                                    <option value={''}>- SELECCIONE -</option>
                                    {Remitentes.map((r) => {
                                        return (
                                            <option key={r} value={r}>{r}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Label>
                        </Col>
                    </Row>
                    <Row className="justify-content-between justify-items-end">
                        <Col xs={2}>
                            <Form.Label style={{width:'100%'}}>
                                Tipo Operacion
                                <Form.Control
                                    as='select'
                                    value={TipoOperacion!}
                                    onChange={(e) => {
                                        setTipoOperacion(e.target.value)
                                    }}
                                    className='form-select form-select-sm'
                                >
                                    <option value={''} key={'0'}>- SELECCIONE -</option>
                                    <option value={'I'} key={'I'}>IMPORTACION</option>
                                    <option value={'E'} key={'E'}>EXPORTACION</option>
                                </Form.Control>
                            </Form.Label>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{width:'100%'}}>
                                Estado
                                <Form.Control
                                    as='select'
                                    value={Programado!}
                                    onChange={(e) => {
                                        setProgramado(+e.target.value)
                                    }}
                                    className='form-select form-select-sm'
                                >
                                    <option value={0} key={0}>- SELECCIONE -</option>
                                    <option value={1} key={1}>EN PLATAFORMA</option>
                                    <option value={2} key={2}>NO EN PLATAFORMA</option>
                                </Form.Control>
                            </Form.Label>
                        </Col>
                        <Col xs={8} className="d-flex align-items-end">
                            <div style={{textAlign:'end', width:'100%'}}>
                                <Button variant='primary' onClick={() => getCuentas()} style={{marginRight:'10px'}}>Buscar</Button>
                                {['Administrador', 'Facturacion', 'Sup. Facturacion'].includes(Perfil) ? <Button variant='primary' onClick={() => {selectFile()}} style={{marginRight:'10px'}}>Cargar Estados</Button> : ''}
                                <Button variant='success' onClick={() => {downloadEstadosCuenta()}} style={{marginRight:'10px'}}><BsFileEarmarkExcelFill/> Excel</Button>
                                <input type="file" ref={hiddenFileInputRef} style={{display:'none'}}
                                    onChange={(e:any) => {
                                        uploadFile(e.target.files[0]);
                                        setFile(e.target.files[0]);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br />
          <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
            <AgGridReact
              rowData={Cuentas}
              columnDefs={columnDefs}
              pagination={true}
              paginationAutoPageSize={true}
              //ref={gridRef}
              /* rowSelection={'multiple'}
              rowMultiSelectWithClick={true} */
              getRowStyle={getRowStyle}
              onCellValueChanged={onCellValueChanged}
            ></AgGridReact>
          </div>
          <MsgInformativo
            show={show}
            msg={msg}
            header={header}
            msgColor={msgColor}
            closeToast={function (arg: boolean): void {
            setShow(false)
            }}
        />
        </div>
    )
}