import { DTOPlantasAsignadas } from '../../DTO/DTOPlantasAsignadas';
import { DTOUsuarioPlanta } from '../../DTO/DTOUsuarioPlanta';
import { ICatPlantas } from '../../Interfaces/Catalogos/ICatPlantas';
import http from '../common/http-common';

class CatPlantasService {
    Get(idCliente: number){
        return http.get<ICatPlantas[]>(`Catalogos/CatPlantas?IdUsuario=${idCliente}`)
    }
    Asignadas(idUsuario: number){
        return http.get<DTOPlantasAsignadas[]>(`Catalogos/CatPlantas/Asignadas?IdUsuario=${idUsuario}`)
    }
    Asignar(dto: DTOUsuarioPlanta){
        return http.post<DTOUsuarioPlanta>(`Catalogos/CatPlantas/Asignar`, dto)
    }
}

export default new CatPlantasService()