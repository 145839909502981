import { DTOEstadoCuentaAlen } from "../../DTO/EstadosCuenta/DTOEstadoCuentaAlen";
import { DTOEstadoCuentaAlenFilters } from "../../DTO/EstadosCuenta/DTOEstadosCuentaAlenFilters";
import http from "../common/http-common";

class EstadosCuentaAlenService {
    Append(formData: FormData){
        return http.post<DTOEstadoCuentaAlen[]>(`ReporteEstadosCuentaAlen/Append`,formData,{
            headers:{
                "Content-Type":'multipart/form-data'
            }
        })
    }

    AppendOrdenCompra(data:DTOEstadoCuentaAlen){
        return http.post<DTOEstadoCuentaAlen[]>(`ReporteEstadosCuentaAlen/AppendOrdenCompra`,data)
    }

    AppendObservaciones(data:DTOEstadoCuentaAlen){
        return http.post<DTOEstadoCuentaAlen[]>(`ReporteEstadosCuentaAlen/AppendObservaciones`,data)
    }

    AppendProgramacionesPago(formData: FormData){
        return http.post<DTOEstadoCuentaAlen[]>(`ReporteEstadosCuentaAlen/AppendProgramacionesPago`,formData,{
            headers:{
                "Content-Type":'multipart/form-data'
            }
        })
    }

    GetAll(filters: DTOEstadoCuentaAlenFilters){
        return http.get<DTOEstadoCuentaAlen[]>(`ReporteEstadosCuentaAlen/GetAll`, {
            params:filters
        })
    }

    ExportEstadosCuenta(cuentas: DTOEstadoCuentaAlen[]){
        return http.post(`ReporteEstadosCuentaAlen/Export`, cuentas, {responseType: 'arraybuffer'})
    }
}

export default new EstadosCuentaAlenService();