import { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap'
import { FaPlus, FaSearch } from 'react-icons/fa'
import AvisosEmbarquesService from '../../../Services/Clientes/AvisosArribos.Service'
import { AxiosError } from 'axios'
import { AgGridReact } from 'ag-grid-react'
import { AvisosArribosForm } from './AvisosArribosForm'
import { RowClickedEvent } from 'ag-grid-community'
import { IconContext } from 'react-icons'
import { BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs'
import { RptAvisosArribos } from '../../../DTO/Clientes/AvisosArribos/RptAvisosArribos'
import { AvisosArribosDocuments } from './AvisosArribosDocuments'
import AvisosFileManagerServices from '../../../Services/Utils/AvisosFileManager.Services'
import { DTOAvisoArriboDocumentos } from '../../../DTO/Clientes/AvisosArribos/DTOAvisoArriboDocumentos'
import { MdWarning } from 'react-icons/md'


export const AvisosArribos = () => {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [avisos, setAvisos] = useState<RptAvisosArribos[]>([])
  const [documentos, setDocumentos] = useState<DTOAvisoArriboDocumentos[]>([])
  const [filteredAvisos, setFilteredAvisos] = useState<RptAvisosArribos[]>([])
  const [showModalAvisos, setShowModalAvisos] = useState(false)
  const [id, setId] = useState(0)
  const [columnDefs] = useState([
    { field: 'id', headerName: 'id', width: 70, sortable: true, filter: true },
    { field: 'guiaEnvio', headerName: 'Guia Envio', /* width: 70, */ sortable: true, filter: true },
    { field: 'planta', headerName: 'Planta', /* width: 70, */ sortable: true, filter: true },
    { field: 'ordenCompra', headerName: 'Orden Compra', /* width: 70, */ sortable: true, filter: true },
    { field: 'factura', headerName: 'Factura', /* width: 70, */ sortable: true, filter: true },
    { field: 'packingList', headerName: 'Packing List', /* width: 70, */ sortable: true, filter: true },
    { field: 'incoterm', headerName: 'Incoterm', /* width: 70, */ sortable: true, filter: true },
    { field: 'contacto', headerName: 'Contacto', /* width: 70, */ sortable: true, filter: true },
    { field: 'aduana', headerName: 'Aduana', /* width: 70, */ sortable: true, filter: true },
    { field: 'informacionTecnica', headerName: 'Información Tec.', /* width: 70, */ sortable: true, filter: true },
    { field: 'instruccionesEntrega', headerName: 'Instrucciones', /* width: 70, */ sortable: true, filter: true },
    { field: 'usuario', headerName: 'Usuario', /* width: 70, */ sortable: true, filter: true },
    { field: 'fechaHoraCreacion', headerName: 'Fecha Hora Captura', /* width: 70, */ sortable: true, filter: true },
    { 
      field: 'idFactura',
      headerName: 'Factura',
      cellRenderer: (params:any) => {
        if(params.data.facturaComentarios.trim().length > 0){
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: '#ff7300', size: '25px' }}>
                <MdWarning />
              </IconContext.Provider>
            </div>
          )
        }else if(params.value > 0){
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: 'green', size: '25px' }}>
                <BsFillCheckCircleFill />
              </IconContext.Provider>
            </div>
          )
        }else{
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: 'red', size: '25px' }}>
                <BsFillXCircleFill />
              </IconContext.Provider>
            </div>
          )
        }
      },
      /* width: 70, */
      sortable: true,
      filter: true 
    },
    { 
      field: 'idGuia',
      headerName: 'Guía de envio',
      cellRenderer: (params:any) => {
        if(params.data.guiaComentarios.trim().length > 0){
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: '#ff7300', size: '25px' }}>
                <MdWarning />
              </IconContext.Provider>
            </div>
          )
        }else if(params.value > 0){
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: 'green', size: '25px' }}>
                <BsFillCheckCircleFill />
              </IconContext.Provider>
            </div>
          )
        }else{
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: 'red', size: '25px' }}>
                <BsFillXCircleFill />
              </IconContext.Provider>
            </div>
          )
        }
      },
      /* width: 70, */
      sortable: true,
      filter: true 
    },
    { 
      field: 'idFicha',
      headerName: 'Ficha Tecnica',
      cellRenderer: (params:any) => {
        if(params.data.fichaComentarios.trim().length > 0){
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: '#ff7300', size: '25px' }}>
                <MdWarning />
              </IconContext.Provider>
            </div>
          )
        }else if(params.value > 0){
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: 'green', size: '25px' }}>
                <BsFillCheckCircleFill />
              </IconContext.Provider>
            </div>
          )
        }else{
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: 'red', size: '25px' }}>
                <BsFillXCircleFill />
              </IconContext.Provider>
            </div>
          )
        }
      },
      /* width: 70, */
      sortable: true,
      filter: true 
    },
    { field: 'fechaETA', headerName: 'Fecha ETA', cellRenderer: (params: any) => {return params.value.substring(0,10)}, /* width: 70, */ sortable: true, filter: true },
  ])

  useEffect(() => {
    getAvisos()
  },[])

  const getDocumentos = (id: number) => {
    AvisosFileManagerServices.GetAvisoFiles(id)
    .then(resp => setDocumentos(resp.data))
    .catch((error: AxiosError) => console.log(error))
  }

  const getAvisos = () => {
    AvisosEmbarquesService.get(UserId)
    .then(resp => {
      setAvisos(resp.data)
      setFilteredAvisos(resp.data)
    })
    .catch((error: AxiosError) => {
      console.log(error.message)
    })
  }
  
  function getParams(e: RowClickedEvent) {
    abreAviso(e.data.id)
  }

  function abreAviso(id: number) {
    setId(id)
    getDocumentos(id)
    setShowModalAvisos(true)
  }

  const filtraReporte = (e: any) => {
    if (e.target.value.length > 0) {
      setFilteredAvisos(
        avisos.filter(function (row) {
          return (
            row.ordenCompra
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.factura
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.packingList
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.incoterm
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.contacto
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.agenteAduanal
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.informacionTecnica
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.instruccionesEntrega
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.guiaEnvio
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase())
          )
        })
      )
    } else {
      setFilteredAvisos(avisos)
    }
  }

  const handleHide = () => {
    getAvisos()
    setShowModalAvisos(false)
  }

  /* const getRowStyle = (params: any) => {
    var styles = {
        backgroundColor:'',
        // border: '1px solid #aaaaaa',
    }
    if (params.data.diasFaltantesETA <= 15 && params.data.diasFaltantesETA > 7) {
        styles.backgroundColor = 'rgb(255, 129, 0, 0.5)';
    }
    if (params.data.diasFaltantesETA <= 7) {
        styles.backgroundColor = 'rgb(255, 0, 0, 0.5)';
    }

    return styles;
  }; */

  return (
    <>
      <Card>
        <Card.Body>
          <Row className='justify-content-end'>
            <Col xs={5}>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </Col>
            <Col xs={2}>
              <Button variant='success' onClick={()=> getAvisos()}><FaSearch/>&nbsp;Buscar</Button>
            </Col>
            <Col xs={2}>
                {Perfil === 'Clientes' ? <Button onClick={()=> abreAviso(0)}><FaPlus/>&nbsp;Nuevo</Button> : '' }
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <br />
      <div className='ag-theme-alpine' style={{ height: 500, width: '99%' }}>
        <AgGridReact
          rowData={filteredAvisos}
          columnDefs={columnDefs}
          pagination={true}
          paginationAutoPageSize={true}
          //ref={gridRef}
          rowSelection={'multiple'}
          rowMultiSelectWithClick={true}
          onRowClicked={(e) => getParams(e)}
          // getRowStyle={getRowStyle}
        ></AgGridReact>
      </div>
      <Modal 
        show={showModalAvisos} 
        // onHide={handleHide}
        size='lg'
        dialogClassName='modal-90w'
        disableEscapeKeyDown={true}
        centered
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <Card>
            <Card.Header>
              <Col xs={12} style={{textAlign:'right'}}>
                <IconContext.Provider
                  value={{ color: 'red', size: '25px' }}
                >
                  <BsFillXCircleFill style={{cursor:'pointer'}} onClick={handleHide}/>
                </IconContext.Provider>
              </Col>
            </Card.Header>
            <Card.Body>
              <Tabs defaultActiveKey="datosArribo">
                <Tab eventKey='datosArribo' title='Datos de Arribo'>
                  <AvisosArribosForm idAviso={id} UserId={UserId} Perfil={Perfil} Documentos={documentos}
                    updateDocumentos={function (id: number): void {
                      getDocumentos(id)
                    } } />
                </Tab>
                <Tab eventKey='documentos' title='Documentos' tabClassName={Perfil === 'Clientes' || Perfil === 'Corresponsal' ? 'd-none' : ''}>
                  <AvisosArribosDocuments idAviso={id} UserId={UserId} Documentos={documentos}/>
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Modal.Body>
  
      </Modal>
    </>
  )
}
