import { useEffect, useState } from 'react'
import { Form, Row, Col, Button, Tabs, Tab, Table } from 'react-bootstrap'
import { DTOAvisoArribo } from '../../../DTO/Clientes/DTOAvisoArribo'
import AvisosArribosService from '../../../Services/Clientes/AvisosArribos.Service'
import { AxiosError } from 'axios'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import AvisosFileManager from '../../Utils/AvisosFileManager/AvisosFileManager'
import { DTOAvisoArriboDocumentos } from '../../../DTO/Clientes/AvisosArribos/DTOAvisoArriboDocumentos'
import { ICatPlantas } from '../../../Interfaces/Catalogos/ICatPlantas'
import CatPlantasService from '../../../Services/Catalogos/CatPlantas.Service'
import { DTOPlantasAsignadas } from '../../../DTO/DTOPlantasAsignadas'

interface IProps {
    idAviso: number,
    UserId: number,
    Perfil: string,
    Documentos: DTOAvisoArriboDocumentos[]
    updateDocumentos: (id: number) => void
}

export const AvisosArribosForm = ({idAviso, UserId, Perfil, Documentos, updateDocumentos} : IProps) => {
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const [validated, setValidated] = useState(false);
  const [plantas, setPlantas] = useState<DTOPlantasAsignadas[]>([])
  const [id, setId] = useState(idAviso)
  const [ordenCompra, setOrdenCompra] = useState('')
  const [factura, setFactura] = useState('')
  const [packingList, setPackingList] = useState('')
  const [incoterm, setIncoterm] = useState('')
  const [contacto, setContacto] = useState('')
  const [agenteAduanal, setAgenteAduanal] = useState('')
  const [informacionTecnica, setInformacionTecnica] = useState('')
  const [instruccionesEntrega, setInstruccionesEntrega] = useState('')
  const [guiaEnvio, setGuiaEnvio] = useState('')
  const [fechaETA, setFechaETA] = useState<string | undefined>()
  const [solicitudPedido, setSolicitudPedido] = useState('')
  const [hazmat, setHazmat] = useState(false)
  const [perecedero, setPerecedero] = useState(false)
  const [idPlanta, setIdPlanta] = useState(0)
  const [planta, setPlanta] = useState('')
  // const [corresponsales, setCorresponsales] = useState<ICatCorresponsales[]>([])
    useEffect(() => {
        if(id > 0){
            AvisosArribosService.getById(id)
            .then(resp => {
                setOrdenCompra(resp.data.ordenCompra)
                setFactura(resp.data.factura)
                setPackingList(resp.data.packingList)
                setIncoterm(resp.data.incoterm)
                setContacto(resp.data.contacto)
                setAgenteAduanal(resp.data.agenteAduanal)
                setInformacionTecnica(resp.data.informacionTecnica)
                setInstruccionesEntrega(resp.data.instruccionesEntrega)
                setGuiaEnvio(resp.data.guiaEnvio)
                setFechaETA(setDate(resp.data.fechaETA))
                setSolicitudPedido(resp.data.solicitudPedido)
                setHazmat(resp.data.hazmat)
                setPerecedero(resp.data.perecedero)
                setIdPlanta(resp.data.idPlanta)
                setPlanta(resp.data.planta)
                /* CorresponsalesServices.getByAduana(resp.data.agenteAduanal)
                .then(response => setCorresponsales(response.data))
                .catch((error: AxiosError) => {
                  setHeader('Error')
                  setMsg(error.request.responseText)
                  setShowMsg(true)
                }) */
            })
            .catch((error: AxiosError) => {
              setHeader('Error')
              setMsg(error.request.responseText)
              setShowMsg(true)
            })
        }
        CatPlantasService.Asignadas(UserId)
        .then(resp => {
          setPlantas(resp.data)
        })
        .catch((error: AxiosError) => {
          setHeader('Error')
          setMsg(error.request.responseText)
          setShowMsg(true)
        })
    },[])

    function setDate(fecha: string | undefined): string {
      if (!fecha) return ''
      else {
        var dd = fecha?.substring(3, 5)
        var mm = fecha?.substring(0, 2)
        var yyyy = fecha?.substring(6, 10)
        return yyyy + '-' + mm + '-' + dd
      }
    }
    
    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if(form.checkValidity() === true){
          let data: DTOAvisoArribo = {
            id,
            idUsuario: UserId,
            ordenCompra,
            factura,
            packingList,
            incoterm,
            informacionTecnica,
            instruccionesEntrega,
            contacto,
            agenteAduanal,
            guiaEnvio,
            fechaETA,
            solicitudPedido,
            hazmat,
            perecedero,
            idPlanta,
            planta
          }
            AvisosArribosService.append(data)
            .then(resp => {
              setId(resp.data.id)
              setHeader('Informativo')
              setMsg('Información guardada correctamente')
              setShowMsg(true)
            })
            .catch((error: AxiosError) => {
              setHeader('Error')
              setMsg(error.request.responseText)
              setShowMsg(true)
            })
        }
        setValidated(true);
      }
    
    const nuevaReferencia = () => {

    }
    return (
      <div style={{height:'500px', overflowY: 'scroll', padding:'10px 20px 0 20px'}}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className='justify-content-end'>
              <Col xs={3} style={{textAlign:'end'}}>
                <Form.Check type='checkbox' label='¿Peligroso?' inline checked={hazmat} onChange={(e) => {setHazmat(e.target.checked)}} disabled={Perfil !== 'Clientes'}/>
                <Form.Check type='checkbox' label='¿Perecedero?' inline checked={perecedero} onChange={(e) => {setPerecedero(e.target.checked)}} disabled={Perfil !== 'Clientes'}/>
              </Col>
            </Row>
            <Row>
              <Form.Label column xs={4}>
                Guia
                <Form.Control type='text' required value={guiaEnvio} onChange={(e) => setGuiaEnvio(e.target.value)} disabled={Perfil !== 'Clientes'}/>
                <Form.Control.Feedback type="invalid">Debe capturar la guía para guardar</Form.Control.Feedback>
              </Form.Label>
              <Form.Label column xs={4}>
                Orden de compra
                <Form.Control type='text' value={ordenCompra} onChange={(e) => setOrdenCompra(e.target.value)} disabled={Perfil !== 'Clientes'}/>
              </Form.Label>
              <Form.Label column xs={4}>
                Factura
                <Form.Control type='text' value={factura} onChange={(e) => setFactura(e.target.value)} disabled={Perfil !== 'Clientes'}/>
              </Form.Label>
            </Row>
            <Row>
              <Form.Label column xs={4}>
                Aduana
                <Form.Control
                  as='select'
                  className='form-select'
                  value={agenteAduanal} 
                  onChange={(e) => setAgenteAduanal(e.target.value)}
                  disabled={Perfil !== 'Clientes'}
                >
                  <option value={0} key={0}>- SELECCIONE -</option>
                  <option value={190} key={190}>MEXICALI</option>
                  <option value={370} key={370}>CIUDAD HIDALGO</option>
                  <option value={470} key={470}>AICM</option>
                  <option value={480} key={480}>GUADALAJARA</option>
                  <option value={521} key={521}>AEROPUERTO INTERNACIONAL DE MONTERREY</option>
                  <option value={640} key={640}>QUERETARO</option>
                  <option value={850} key={850}>AEROPUERTO INTERNACIONAL FELIPE ANGELES</option>
                </Form.Control>
              </Form.Label>
              <Form.Label column xs={4}>
                Planta
                { Perfil === 'Clientes' ? <>
                  <Form.Control as='select' className='form-select' value={idPlanta} onChange={(e) => setIdPlanta(+e.target.value)} required>
                    <option key={0} value=''>- SELECCIONE -</option>
                    {
                      plantas ? plantas.filter(p => p.asignada == true).map(p => (
                        <option key={p.id} value={p.id}>{p.nombre}</option>
                      )) : ''
                    }
                  </Form.Control> 
                  <Form.Control.Feedback type="invalid">Debe seleccionar una planta para guardar</Form.Control.Feedback>
                  </>
                  :
                  <Form.Control type='text' value={planta} disabled/>
                }
              </Form.Label>
              <Form.Label column xs={2}>
                Packing list
                <Form.Control type='text' value={packingList} onChange={(e) => setPackingList(e.target.value)} disabled={Perfil !== 'Clientes'}/>
              </Form.Label>
              <Form.Label column xs={2}>
                Incoterm
                <Form.Control type='text' value={incoterm} onChange={(e) => setIncoterm(e.target.value)} disabled={Perfil !== 'Clientes'}/>
              </Form.Label>
            </Row>
            <Row>
              <Form.Label column xs={7}>
                Contacto
                <Form.Control type='text' value={contacto} onChange={(e) => setContacto(e.target.value)} disabled={Perfil !== 'Clientes'}/>
              </Form.Label>
              <Form.Label column xs={2}>
                Fecha ETA
                <Form.Control type='date' value={fechaETA} onChange={(e) => setFechaETA(e.target.value)} disabled={Perfil !== 'Clientes'}/>
              </Form.Label>
              <Form.Label column xs={3}>
                SP Agente Aduanal
                <Form.Control type='text' value={solicitudPedido} onChange={(e) => setSolicitudPedido(e.target.value)} disabled={Perfil !== 'Clientes'}/>
              </Form.Label>
            </Row>
            <Row>
              <Form.Label column xs={6}>
                Información técnica
                <Form.Control as='textarea' rows={4} value={informacionTecnica} onChange={(e) => setInformacionTecnica(e.target.value)} disabled={Perfil !== 'Clientes'}/>
              </Form.Label>
              <Form.Label column xs={6}>
                Instrucciones de entrega
                <Form.Control as='textarea' rows={4} value={instruccionesEntrega} onChange={(e) => setInstruccionesEntrega(e.target.value)} disabled={Perfil !== 'Clientes'}/>
              </Form.Label>
            </Row>
            { Perfil === 'Clientes' ?
              <Row>
                {
                  Documentos ? Documentos.map(doc => {
                    return (
                    <Col xs={4}>
                      PDF {doc.documento}
                      <Form.Control isInvalid={doc.rechazado} hidden></Form.Control>
                      <AvisosFileManager 
                        IDProcess={doc.idRutaArchivo} 
                        IDUser={UserId} 
                        IdFile={id} 
                        FileName={''} 
                        Leyenda={'Seleccione el PDF'} 
                        canDelete={true} 
                        FileType={['pdf', 'PDF']} 
                        width={'100%'} 
                        height={100} 
                        onAppendFM={function (idFile: number): void {
                          updateDocumentos(id)
                        } }
                        onDelete={(val: boolean) => {
                          if(val)
                            updateDocumentos(id)
                        }}
                      />
                      <Form.Control.Feedback type='invalid'>{doc.comentarios}</Form.Control.Feedback>
                    </Col>
                    )
                  }) : ''
                } 
              </Row> : ''
            }
            <Row className={Perfil === 'Clientes' ? 'justify-content-end' : 'd-none'}>
              <Col xs={2} style={{textAlign:'right'}}>
                <Button type='submit' /* onClick={(e) => handleSubmit(e)} */>Guardar</Button>
              </Col>
            </Row>
            <Row className={Perfil === 'Corresponsalias' ? 'justify-content-end' : 'd-none'}>
              <Col xs={2} style={{textAlign:'right'}}>
                <Button onClick={() => nuevaReferencia()}>Crear Referencia</Button>
              </Col>
            </Row>
          </Form>
        <MsgInformativo
          show={show}
          msg={msg}
          header={header}
          msgColor={msgColor}
          closeToast={() => {
            setShowMsg(false)
          }}
        />
      </div>
  )
}
