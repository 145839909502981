import http from "../common/http-common";
import IRespuesta from "../../Interfaces/IRespuesta";
import IAvisosFileManager from "../../Interfaces/Utils/IAvisosFileManager";
import { DTOAvisoArriboDocumentos } from "../../DTO/Clientes/AvisosArribos/DTOAvisoArriboDocumentos";

class AvisosFileManagerService {
  AppendFile(formData: FormData, IDUser: number, IDProcess: number, IdFile: number){
    return http.post(`/AvisosFileManager/AppendFileByProcess?IdUsuario=${IDUser}&Proceso=${IDProcess}&IdAvisoArribo=${IdFile}&crud=1`, formData, 
      {
        headers:{
          "Content-Type":'multipart/form-data'
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity
      }
    )
  }

  getFileContent(id: number, Proceso: number) {
    return http.get(`/AvisosFileManager/getFile?id=${id}&proceso=${Proceso}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  } 
  getFileInfoFromProcess(id: number, proc: number) {
    return http.get<IAvisosFileManager>(`/AvisosFileManager/GetFileInfoByProcess?id=${id}&Proceso=${proc}`);
  }
  getFileInfoById(id: number) {
    return http.get<IAvisosFileManager>(`/AvisosFileManager/GetFileInfoById?id=${id}`);
  }
  DeleteFile(id: number) {
    return http.delete<IRespuesta>(`/AvisosFileManager/DeleteById/${id}`);
  }

  getFileContentById(id: number) {
    return http.get<ArrayBuffer>(`/AvisosFileManager/GetFileById?fileId=${id}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  }
  GetAvisoFiles(id:number) {
    return http.get<DTOAvisoArriboDocumentos[]>(`/AvisosFileManager/GetAvisoFiles/${id}`)
  }
  MarkAsRejected(document:DTOAvisoArriboDocumentos){
    return http.post<DTOAvisoArriboDocumentos>(`/AvisosFileManager/MarkAsRejected`, document)
  }
}
export default new AvisosFileManagerService();