import http from "../common/http-common";
import ICatCorresponsales from '../../Interfaces/Catalogos/ICatCorresponsales'
import ICorresponsalFacturas from "../../Interfaces/Corresponsales/ICorresponsalFacturas";
import ICorresponsalesZips from "../../Interfaces/Corresponsales/ICorresponsalesZips";


class CorresponsalesDataService {
  // Catalogo de Corresponsales
  getAll() {
    return http.get<ICatCorresponsales[]>(`/Catalogos/Corresponsales/getAll`);
  }
  getByAduana(aduana: string) {
    return http.get<ICatCorresponsales[]>(`/Catalogos/Corresponsales/GetByAduana?Aduana=${aduana}`);
  }
  getAllFormated() {
    return http.get<ICatCorresponsales[]>(`/Catalogos/Corresponsales/getAllFormated`);
  }
  Append(data: ICatCorresponsales) {
    return http.post<ICatCorresponsales>("/Catalogos/Corresponsales/Append", data);
  }
  Delete(id: number) {
    return http.delete<ICatCorresponsales>(`/Catalogos/Corresponsales/Delete/${id}`);
  }

  /* // Corresponales: Archivos ZIP
  CorresponsalesZipLog(data: ICorresponsalesZips) {
    return http.post<ICorresponsalesZips>("/Corresponsales/CorresponsalesZipLog", data);
  }
  getCorresponsalesZipFiles(idTrafico: number) {
    return http.get<ICorresponsalesZips[]>(`/Corresponsales/getCorresponsalesZipFiles?idTrafico=${idTrafico}`);
  }
  getZIPContent(data : ICorresponsalesZips) {
    return http.get<ArrayBuffer>(`/Corresponsales/getCorresponsalesZipFileContent?Archivo=${data.archivo}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  }  
  DeleteCorresponsalesZipFile(id: number) {
    return http.delete<ICorresponsalFacturas>(`/Corresponsales/DeleteCorresponsalesZipFile/${id}`);
  } */

}
export default new CorresponsalesDataService();