import http from "../common/http-common";

import DTORptCorresponsalesTraficos from "../../DTO/Corresponsales/DTORptCorresponsalesTraficos";
import { DTOReporteGeneral } from "../../DTO/Facturacion/DTOReporteGeneral";

class ReportesFacturacionService{
    GetReporteGeneral(data: DTORptCorresponsalesTraficos){
        return http.get<DTOReporteGeneral[]>(`ReportesFacturacion/CuentasFacturadas?Inicio=${data.Inicio}&Fin=${data.Fin}&TipoOperacion=${data.TipoOperacion}&NoCliente=${data.NoCliente}&IdCorresponsal=${data.IdCorresponsal}&Pedimento=${data.Pedimento}&Aduana=${data.Aduana}&Patente=${data.Patente}&Proceso=${data.Proceso}&Modo=${data.Modo}`)
    }

    DownloadExcel(data: DTORptCorresponsalesTraficos){
        return http.get(`ReportesFacturacion/FacturacionExcel?Inicio=${data.Inicio}&Fin=${data.Fin}&TipoOperacion=${data.TipoOperacion}&NoCliente=${data.NoCliente}&IdCorresponsal=${data.IdCorresponsal}&Pedimento=${data.Pedimento}&Aduana=${data.Aduana}&Patente=${data.Patente}&Proceso=${data.Proceso}&Modo=${data.Modo}`, {responseType: 'blob'});
    }
}

export default new ReportesFacturacionService()