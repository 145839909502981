import React, { FC, useEffect, useState } from 'react'
import DashboardCorresponsales from './DashboardCorresponsales'
import { DashboardContabilidad } from './DashboardContabilidad'
import { Col, Container, Row } from 'react-bootstrap'
import { DashboardFacturacion } from './DashboardFacturacion'
import DashboardClientes from './DashboardClientes'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { DashboardSistemas } from './DashboardSistemas'

interface IProps {}

export default function Dashboard(props: IProps) {
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [User, setUser] = useState(() => {
    const stickyValue = window.localStorage.getItem('User')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  return (
    <div>
      <br />
      <Container>
        <Row xs={1} md={3} className='g-4'>
          {[Depto === 'Sistemas' ? <DashboardSistemas /> : ''].map(
              (item) => {
                return <React.Fragment>{item}</React.Fragment>
              }
          )}
          {[['Direccion', 'Sistemas', 'Corresponsalias'].includes(Depto) ? <DashboardCorresponsales Perfil={Perfil} /> : ''].map(
            (item) => {
              return <React.Fragment>{item}</React.Fragment>
            }
          )}
          {[['Direccion', 'Sistemas', 'Contabilidad'].includes(Depto) ? <DashboardContabilidad Depto={Depto} Perfil={Perfil}/> : ''].map((item) => {
            return <React.Fragment>{item}</React.Fragment>
          })}
          {[['Direccion', 'Sistemas', 'Facturacion'].includes(Depto) ? <DashboardFacturacion Depto={Depto} Perfil={Perfil}/> : ''].map((item) => {
            return <React.Fragment>{item}</React.Fragment>
          })}
          {[ Perfil === 'Clientes' ? <DashboardClientes /> : ''].map(
              (item) => {
                return <React.Fragment>{item}</React.Fragment>
              }
          )}
        </Row>
      </Container>
    </div>
  )
}
