import http from "../common/http-common";
import DashboardCorresponsales from "../../Interfaces/Dashboard/DashboardCorresponsales";

class Dashboard_Corresponsales_DataService {
  getDisk() {
    return http.get<DashboardCorresponsales>(`/Dashboard/Corresponsales/GetDiskUsage`);
  }
  getCorresponsales() {
    return http.get<DashboardCorresponsales[]>(`/Dashboard/Corresponsales/Get`);
  }
  getTipoCambio(Fecha: string) {
    return http.get<number>(`/Dashboard/Corresponsales/GetTipoCambio?Fecha=${Fecha}`);
  }
  getSolicitudesEliminarCuenta(){
    return http.get<DashboardCorresponsales>(`/Dashboard/Corresponsales/SolicitudesEliminarCuenta`);
  }
}
export default new Dashboard_Corresponsales_DataService();