import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Container, Form, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import ItemMenu from './Interfaces/Catalogos/IItemMenu'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import usuariosServices from './Services/Catalogos/Usuarios.Services'
import { logued } from './store/features/userStatusSlice/userStatusSlice'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from './store/store'
import { MsgInformativo } from './Components/Utils/Toast/msgInformativo'
import { IconContext } from 'react-icons'
import { BsPersonCircle } from 'react-icons/bs'
import {TbDoorExit} from 'react-icons/tb'
import Submenu from './Components/Submenu/Submenu'

function App() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let _menu: ItemMenu[] = []
  const [mainMenu, setMainMenu] = useState(_menu)
  const userLogued = useSelector((state: RootState) => state.userStatus.isLogged)
  const [show, setShow] = useState(false)
  const [msg, setMsg] = useState('')
  const [header, setHeader] = useState('')
  const msgColor = 'primary'
  const menu = localStorage.getItem('menu') ? localStorage.getItem('menu') : ''
  const User = () => {
    const stickyValue = window.localStorage.getItem('User')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  }
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null
      ? JSON.parse(stickyValue).substring(0, 4) + '.'
      : ''
  })

  useEffect(() => {
    if (menu && userLogued) {
      setMainMenu(JSON.parse(menu))
    } else {
      setMainMenu([])
    }
  }, [menu, userLogued])

  useEffect(() => {
    usuariosServices
      .validate()
      .then((response) => {
        if (response.status === 200) {
          dispatch(logued(true))
        }
      })
      .catch((e: Error) => {
        if (!window.location.href.indexOf('login')) {
          localStorage.setItem('menu', '')
          localStorage.setItem('token', '')
          dispatch(logued(false))
          setHeader('Informativo')
          setMsg('Su session ha expirado, necesita volver a loguerse')
          setShow(true)
          
          navigate('/login')
        }
      })
  }, [dispatch, navigate])

  const Navigate = () => {
    var Perfil = JSON.parse(window.localStorage.getItem('Perfil')!)
    if(Perfil === 'Corresponsales'){
      navigate('/RptCorresponsalesTraficos/proc=1/modo=1')  
    }else{
      navigate('/')
    }
  }

  return (
    <div className="App">
      <Navbar className="custom-theme" bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={Navigate} style={{ color: '#FFFFFF', cursor: 'pointer' }}>
            GEMCO
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {mainMenu
                ? mainMenu.map((itemMenu, index) => {
                    if (itemMenu.padreId === 0) {
                      return (
                        <>
                          <Submenu
                            descripcion={itemMenu.descripcion}
                            key={itemMenu.id}
                            allItems={mainMenu}
                            submenu={mainMenu.filter(function (item) {
                              return item.padreId === itemMenu.id
                            })}
                          />
                        </>
                      )
                    }
                  })
                : ''}
            </Nav>
            <Form className="d-flex">
              {!userLogued ? (
                <>
                  <Nav.Link href="/#/login">login</Nav.Link>&nbsp;
                </>
              ) : (
                <>
                  <Nav.Link href="/#/logout">Logout</Nav.Link>
                  <div style={{ paddingTop: '5px', paddingLeft:'5px' }}></div>
                  <IconContext.Provider value={{ size: '25px' }}>
                    <TbDoorExit />
                  </IconContext.Provider>
                </>
              )}
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </div>
  )
}

export default App

