import React, { FC, useEffect, useState } from 'react'
import { Alert, Card, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { BsCloudArrowUpFill, BsFillPlusCircleFill } from 'react-icons/bs'
import FileManager from '../../Utils/FileManager/FileManager'
import CuentasComplementariasDS from '../../../Services/Corresponsalias/Corresponsales.CuentasComplementarias.Services'
import MFileManagerDataService from '../../../Services/Utils/MFileManager.Service'
import ICorresponsalCuentasComplementarias from '../../../Interfaces/Corresponsales/ICorresponsalCuentasComplementarias'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import DataTable from 'react-data-table-component'
import DTOCorresponsalCuentaComplementaria from '../../../DTO/Corresponsales/DTOCuentaComplementaria'
import { SelectEstatus } from './SelectEstatus/SelectEstatus'
import { FcAbout } from 'react-icons/fc'
import DTOLogCorresponsalComplementariaEstatus from '../../../DTO/Corresponsales/DTOLogCorresponsalComplementariaEstatus'
import FileManagerDS from '../../../Services/Utils/FileManager.Services'
import { AiOutlineClose } from 'react-icons/ai'
import { MdCloudUpload, MdOutlineInventory, MdOutlineSave } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { FiSave } from 'react-icons/fi'
import ICorresponsalCuentaComplementariaStatus from '../../../Interfaces/Corresponsales/ICorresponsalCuentaComplementariaEstatus'
import { updateCorresponsalesCuentasComplementariasEstatus } from '../../../store/features/Corresponsales/CorresponsalesCuentasComplementariasEstatusSlice'

interface IProps {
  IDTrafico: number
  UserId: number
}

export const CuentasComplementarias: FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const mCCCEstatus = useSelector(
    (state: RootState) => state.CCueCompEstatus.CorresponsalesCuentasComplementariasEstatus
  )
  const [showModal, setShowModal] = useState(false)
  const [showModalLog, setShowModalLog] = useState(false)
  const [showModalCatalogo, setShowModalCatalogo] = useState(false)
  const [DTData, setDTData] = useState<ICorresponsalCuentasComplementarias[]>([])
  const [CuentaComplementaria, setCuentaComplementaria] = useState<ICorresponsalCuentasComplementarias[]>([])
  const [HistorialEstatus, setHistoriaEstatus] = useState<DTOLogCorresponsalComplementariaEstatus[]>([])
  const [IDCuentaComplementaria, setIdCuentaComplementarias] = useState(0)
  const [IDPDF, setIDPDF] = useState(0)
  const [IDXML, setIDXML] = useState(0)
  const [IDEstatus, setIDEstatus] = useState(0)
  const [Estatus, setEstatus] = useState('')
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [MsgTime, setMsgTime] = useState(3000)

  const colCuentasComplementarias = [
    {
      name: 'id',
      width: '60px',
      selector: (row: ICorresponsalCuentasComplementarias) => row.id,
      sortable: true,
    },
    {
      name: '',
      width: '20px',
      cell: (row: ICorresponsalCuentasComplementarias) => {
        return row.archivoXML ? (
          <div
            style={{
              cursor: 'pointer',
              visibility: Depto === 'Corresponsalias' && row.estatus !== 7 ? 'visible' : 'hidden',
            }}
            onClick={() => {
              clearFile(row.id, 1, row.idXML)
            }}
          >
            <IconContext.Provider value={{ color: 'red', size: '15px' }}>
              <AiOutlineClose />
            </IconContext.Provider>
          </div>
        ) : (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setPreloader(row.id)
            }}
          >
            <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
              <MdCloudUpload />
            </IconContext.Provider>
          </div>
        )
      },
    },
    {
      name: 'XML',
      width: '300px',
      cell: (row: ICorresponsalCuentasComplementarias) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              getFileContent(row.idXML, 22, row.archivoXML)
            }}
          >
            {row.archivoXML}
          </div>
        )
      },
    },
    {
      name: '',
      width: '20px',
      cell: (row: ICorresponsalCuentasComplementarias) => {
        return row.archivoPDF ? (
          <div
            style={{
              cursor: 'pointer',
              visibility: Depto === 'Corresponsalias' && row.estatus !== 7 ? 'visible' : 'hidden',
            }}
            onClick={() => {
              clearFile(row.id, 2, row.idPDF)
            }}
          >
            <IconContext.Provider value={{ color: 'red', size: '15px' }}>
              <AiOutlineClose />
            </IconContext.Provider>
          </div>
        ) : (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setPreloader(row.id)
            }}
          >
            <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
              <MdCloudUpload />
            </IconContext.Provider>
          </div>
        )
      },
    },
    {
      name: 'PDF',
      width: '300px',
      cell: (row: ICorresponsalCuentasComplementarias) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              getFileContent(row.idPDF, 21, row.archivoPDF)
            }}
          >
            {row.archivoPDF}
          </div>
        )
      },
    },
    {
      name: 'Estatus',
      width: '400px',
      cell: (row: ICorresponsalCuentasComplementarias) => {
        return <SelectEstatus IDEstatus={row.estatus} IDCuenta={row.id} Enabled={row.estatus === 7} />
      },
    },
    {
      name: 'Historico',
      width: '110px',
      cell: (row: ICorresponsalCuentasComplementarias) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            showHistoricoEstatus(row.id)
          }}
        >
          <IconContext.Provider value={{ color: 'red', size: '25px' }}>
            <FcAbout />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  const setPreloader = (id: number) => {
    setIdCuentaComplementarias(id)
    setIDPDF(0)
    setIDXML(0)
    setShowModal(true)
  }

  const clearFile = (IDCuenta: number, witchFile: number, idFile: number) => {
    CuentasComplementariasDS.ClearFile(IDCuenta, witchFile)
      .then((response) => {
        FileManagerDS.DeleteFile(idFile)
          .then((response) => {
            loadCuentaComplementaria()
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error: ' + e)
            setShowMsg(true)
            return
          })
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const createCuentaComplementaria = () => {
    const data: DTOCorresponsalCuentaComplementaria = {
      id: 0,
      idTrafico: props.IDTrafico,
      idFile: 0,
    }
    CuentasComplementariasDS.Append(data)
      .then((response) => {
        // setCuentaComplementaria(response.data)
        setIdCuentaComplementarias(response.data.id)
        setIDPDF(response.data.idPDF)
        setIDXML(response.data.idXML)
        setShowModal(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCuentaComplementaria = () => {
    CuentasComplementariasDS.Get(props.IDTrafico)
      .then((response) => {
        setDTData(response.data)
        setIDPDF(0)
        setIDXML(0)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    setIDPDF(0)
    setIDXML(0)
  }, [IDCuentaComplementaria])

  useEffect(() => {
    loadCuentaComplementaria()
  }, [])

  const showHistoricoEstatus = (id: number) => {
    CuentasComplementariasDS.GetLogEstatus(id)
      .then((response) => {
        setHistoriaEstatus(response.data)
        setShowModalLog(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const updateFile = (idFile: number) => {
    if (IDCuentaComplementaria === 0) {
      setHeader('Error')
      setMsg('Para proceder, es necesario que cree una cuenta complementaria o seleccione una existente')
      setShowMsg(true)
      return
    }
    const data: DTOCorresponsalCuentaComplementaria = {
      id: IDCuentaComplementaria,
      idFile: idFile,
      idTrafico: props.IDTrafico,
    }
    CuentasComplementariasDS.Append(data)
      .then((response) => {
        loadCuentaComplementaria()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const getFileContent = (id: number, proceso: number, archivo: string) => {
    if (id > 0) {
      MFileManagerDataService.getFileContentById(id, proceso)
        .then((response: any) => {
          if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', archivo)
            document.body.appendChild(link)
            link.click()
            /* if (archivo.toLowerCase().endsWith('.pdf')) {
              console.log(response.data)
              const blob = new Blob([response.data], { type: 'application/pdf' })
              const url = window.URL.createObjectURL(blob)
              window.open(url)
            } else if (archivo.toLowerCase().endsWith('.xml')) {
              console.log(response.data)
              const blob = new Blob([response.data], { type: 'application/xml' })
              const url = window.URL.createObjectURL(blob)
              window.open(url)

              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', archivo)
              document.body.appendChild(link)
              link.click()
            } */
          } else {
            setHeader('Error')
            setMsg('No existe ningun archivo asignado')
            setShowMsg(true)
            return
          }
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('A este concepto no se le ha anexado archivo')
          setShowMsg(true)
          return
        })
    }
  }

  const saveEstatus = () => {
    const data: ICorresponsalCuentaComplementariaStatus = {
      id: IDEstatus,
      estatus: Estatus,
    }
    CuentasComplementariasDS.AppendEstatus(data)
      .then((response) => {
        dispatch(updateCorresponsalesCuentasComplementariasEstatus(response.data))
        setIDEstatus(0)
        setEstatus('')
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <Row>
        <Col style={{ textAlign: 'right', visibility: Depto === 'Contabilidad' ? 'visible' : 'hidden' }}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setShowModalCatalogo(true)
            }}
            title='De un click aqui para agregar abrir el catalogo de conceptos de rechazo'
          >
            <IconContext.Provider value={{ color: 'green', size: '35px' }}>
              <MdOutlineInventory />
            </IconContext.Provider>
          </div>
        </Col>
        <Col xs={10}></Col>
        <Col style={{ visibility: Depto !== 'Contabilidad' ? 'visible' : 'hidden' }}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              createCuentaComplementaria()
            }}
            title='De un click aqui para agregar una nueva cuenta complementaria'
          >
            <IconContext.Provider value={{ color: 'green', size: '35px' }}>
              <BsFillPlusCircleFill />
            </IconContext.Provider>
          </div>
        </Col>
      </Row>
      <Row style={{ paddingTop: '10px' }}>
        <Card>
          <Col xs={12}>
            <DataTable
              noHeader
              defaultSortFieldId={'id'}
              defaultSortAsc={true}
              pagination
              highlightOnHover
              columns={colCuentasComplementarias}
              data={DTData}
            />
          </Col>
        </Card>
      </Row>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <FileManager
                IDUser={props.UserId}
                width={350}
                height={200}
                IDProcess={22}
                IdFile={IDCuentaComplementaria}
                FileName={''}
                canDelete={true}
                FileType={['xml']}
                Leyenda={'Seleccione el archivo XML, arrastrelo hasta aqui y sueltelo para subirlo al servidor...'}
                onAppendFM={function (idFile: number): void {
                  setIDXML(idFile)
                  updateFile(idFile)
                }}
              />
            </Col>
            <Col xs={6}>
              <FileManager
                IDUser={props.UserId}
                width={350}
                height={200}
                IDProcess={21}
                IdFile={IDCuentaComplementaria}
                FileName={''}
                canDelete={true}
                FileType={['pdf']}
                Leyenda={'Seleccione el archivo PDF, arrastrelo hasta aqui y sueltelo para subirlo al servidor...'}
                onAppendFM={function (idFile: number): void {
                  setIDPDF(idFile)
                  updateFile(idFile)
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalLog}
        onHide={() => {
          setShowModalLog(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          {HistorialEstatus.length > 0 ? (
            <Card>
              <table>
                <tr>
                  <td>Registrado</td>
                  <td>Estatus</td>
                </tr>
                {HistorialEstatus
                  ? HistorialEstatus.map((item, index) => {
                      return (
                        <tr key={item.estatus}>
                          <td>{item.fCreacion}</td>
                          <td>{item.sEstatus}</td>
                        </tr>
                      )
                    })
                  : ''}
              </table>
            </Card>
          ) : (
            <Alert variant='primary'>
              <Alert.Heading style={{ textAlign: 'center' }}>No hay informacion al respecto</Alert.Heading>
            </Alert>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalCatalogo}
        onHide={() => {
          setShowModalCatalogo(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          {mCCCEstatus.length > 0 ? (
            <>
              <Card>
                <Row>
                  <Col xs={1}>
                    <FloatingLabel controlId='IdEstatus' label='id' className='mb-3'>
                      <Form.Control type='text' placeholder='id' value={IDEstatus} disabled={true} />
                    </FloatingLabel>
                  </Col>
                  <Col xs={10}>
                    <FloatingLabel controlId='IdEstatus' label='Estatus' className='mb-3'>
                      <Form.Control
                        type='text'
                        placeholder='Estatus'
                        value={Estatus}
                        onChange={(e) => {
                          setEstatus(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={1} style={{ paddingTop: '10px', paddingRight: '50px', cursor: 'pointer' }}>
                    <div
                      onClick={() => {
                        saveEstatus()
                      }}
                    >
                      <IconContext.Provider value={{ color: 'blue', size: '35px' }}>
                        <FiSave />
                      </IconContext.Provider>
                    </div>
                  </Col>
                </Row>
              </Card>
              <Card>
                <Row style={{ fontWeight: 'bold' }}>
                  <Col xs={1}>id</Col>
                  <Col xs={10}>Estatus</Col>
                  <Col xs={1}></Col>
                </Row>
                {mCCCEstatus
                  ? mCCCEstatus
                      .filter((item) => {
                        return item.id > 1
                      })
                      .map((item, index) => {
                        return (
                          <Row
                            key={item.estatus}
                            onClick={() => {
                              setIDEstatus(item.id)
                              setEstatus(item.estatus)
                            }}
                          >
                            <Col xs={1}>{item.id}</Col>
                            <Col xs={10}>{item.estatus}</Col>
                            <Col xs={1}></Col>
                          </Row>
                        )
                      })
                  : ''}
              </Card>
            </>
          ) : (
            <Alert variant='primary'>
              <Alert.Heading style={{ textAlign: 'center' }}>No hay informacion al respecto</Alert.Heading>
            </Alert>
          )}
        </Modal.Body>
      </Modal>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        time={MsgTime}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
