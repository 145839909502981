import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'

const initialState = {
  isLogged: false,
}

export const userStatusSlice = createSlice({
  name: 'userStatus',
  initialState,
  reducers: {
    logued: (state, action: PayloadAction<boolean>) => {
     state.isLogged = action.payload;
    }
  },
})

export const { logued } = userStatusSlice.actions;
//export const selectuserStatus = (state: RootState) => state.userStatus.value
export default userStatusSlice.reducer;
